.page{
    overflow: hidden;
}
.home-page{
    
    .text-zone{
        position: absolute;
        left:10%;
        top:50%;
        transform: translateY(-50%);
        width:80%;
        max-height: 90%;
        float:left;
        z-index: 100;

    }

    h1{
        color:#fff;
        font-size: 56px;
        line-height: 53px;
        margin:0;
        font-family: 'CoolVetica';
        
        font-weight: 400;

        &::before{
            content:'<h1>';
            font-family: 'La Belle Aurore',cursive;
            color:#ffd700;
            font-size:19px;
            position: absolute;
            margin-top: -40px;
            left: -15px;
            opacity:0.6;

        }

        &::after{
            content:'<h1/>';
            font-family: 'La Belle Aurore';
            color:#ffd700;
            font-size:19px;
            position: absolute;
            margin-top: 18px;
            margin-left:20px;
            animation:fadeIn 1s 1.7s backwards;
            
            opacity:0.6;

        }

        
        img{
            width:32px;
            margin-left:20px;
            opacity:0;
            height:auto;
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;

        }
    }
    h2{
        color: #8d8d8d;
        margin:20px 0px 20px ;
        font-weight: 400;
        font-size: 11px;
        width: 70%;
        font-family: sans-serif;
        letter-spacing:3px;
        animation: fadeIn 1s 1.8s backwards;
    }

    .flat-button{
        color:#ffd700;
        font-size:13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding:10px 18px;
        border:1px solid #ffd700;
        margin-top: 25px;
        float:left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;
    &:hover{
        background:#ffd700;
        color:#8d8d8d;
    }
    }

    .sideImage{
        
        padding-top:80px;
        float:right;
        z-index: auto;
        height: auto;
        display: block;
       
        
        .image1{
            position: absolute;
            z-index: 1;
            overflow: hidden;
        }
        .vec{
            position: absolute;
            z-index: 20;
            margin-top:400px;
            overflow: hidden;
        }

    }
}

@media only screen and (max-width:600px) {
.sideImage{
    width:60%;
    margin-left: 40%;
}
}
@media only screen and (min-width:600px) {
.sideImage{
    width:40%;
        padding-top:80px;
        float:right;
        z-index: auto;
        height: auto;
        display: block;
}
}
.portfolio-page{
    position: initial;
    height:100%;
    overflow: auto;

    h1.page-title{
        margin-left: 50px;
        margin-top: 100px;
    }

    .images-container{
        display: flex;
        
        flex-wrap: wrap;
        padding-bottom: 200px;
    }
        .image-box{
            position: relative;
            flex:1 1 50%;
            
            overflow: hidden;
            border-radius: 10px;
            max-width: calc(50% - 10px);
            z-index: 1;
            margin-top: 20px;

            .portfolio-image{
                position: absolute;
                z-index: 2;
                width: 100%;
                height:100%;
                object-fit: cover;
            }
            .content{
                position: absolute;
                width: 100%;
                z-index: 3;
                padding: 10px 20px;
                transition:all 0.3 cubic-bezier(0.645, 0.045, 0.355, 1);
                background: linear-gradient(
                    180deg,
                    rgba(0,0,0,0.2) 0,
                    rgba(0,0,0,1)
                );
                bottom: -70px;
            }
            .title{
                margin-top: 0;
                margin-bottom: 0;
                color:white;
                font-size: 24px;
                font-weight: 500;
                line-height: 24px;
            }
            .description{
                font-size: 14px;
                margin-bottom: 5px;
                color:white;
                font-weight: 700;
            }
            .btn{
                margin-top: 30px;   
                margin-bottom: 10px;
                padding: 0 23px;
                height: 40px;
                line-height: 34px;
                border: 2px solid #ffd700;
                border-radius: 4px;
                font-size: 14px;
                color: white;
                background:transparent;
                text-transform: uppercase;
                font-weight: 700;
                transition: all 0.5 cubic-bezier(0.645, 0.045, 0.355, 1);
                cursor: pointer;
            }
            .btn:hover{
                transform: translateY(-3px);
                background:#ffd700;
            }
            
            &:after{
                content: "";
                background: linear-gradient(180deg,#ffd700,#000);
                position: absolute;
                left:0;
                right:0;
                bottom: 0;
                top:0;
                width: 100%;
                height: 100%;
                z-index: 2;
                transition: all 0.5 cubic-bezier(0.645, 0.045, 0.355, 1);
                opacity: 0;
            }
            &:hover:after{
                opacity: 0.85;
            }

            &:hover .content{
                bottom:0;
                background:transparent;
            }
        }
    
}



@media only screen and (max-width:600px) {
.portfolio-page{
   padding: 0 0px 10px 10px;
}
.image-box{
    position: relative;
    
    height: 250px;
}

}




@media only screen and (min-width:600px) {
    .portfolio-page{
        padding-left: 100px;
        padding-right: 50px;
        width:calc(100%  - 150px);
        position: initial;
        height:100%;
        overflow: auto;
    
        h1.page-title{
            margin-left: 100px;
            margin-top: 100px;
        }
    
        .images-container{
            display: flex;
            gap:15px;
            flex-wrap: wrap;
            padding-bottom: 200px;
        }
            .image-box{
                position: relative;
                flex:1 1 50%;
                height: 400px;
                overflow: hidden;
                border-radius: 10px;
                max-width: calc(50% - 10px);
                z-index: 1;
                margin-top: 20px;
    
                .portfolio-image{
                    position: absolute;
                    z-index: 2;
                    width: 100%;
                    height:100%;
                    object-fit: cover;
                }
                .content{
                    position: absolute;
                    width: 100%;
                    z-index: 3;
                    padding: 10px 20px;
                    transition:all 0.3 cubic-bezier(0.645, 0.045, 0.355, 1);
                    background: linear-gradient(
                        180deg,
                        rgba(0,0,0,0.2) 0,
                        rgba(0,0,0,1)
                    );
                    bottom: -70px;
                }
                .title{
                    margin-top: 0;
                    margin-bottom: 0;
                    color:white;
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 24px;
                }
                .description{
                    font-size: 14px;
                    margin-bottom: 5px;
                    color:white;
                    font-weight: 700;
                }
                .btn{
                    margin-top: 30px;   
                    margin-bottom: 10px;
                    padding: 0 23px;
                    height: 40px;
                    line-height: 34px;
                    border: 2px solid #ffd700;
                    border-radius: 4px;
                    font-size: 14px;
                    color: white;
                    background:transparent;
                    text-transform: uppercase;
                    font-weight: 700;
                    transition: all 0.5 cubic-bezier(0.645, 0.045, 0.355, 1);
                    cursor: pointer;
                }
                .btn:hover{
                    transform: translateY(-3px);
                    background:#ffd700;
                }
                
                &:after{
                    content: "";
                    background: linear-gradient(180deg,#ffd700,#000);
                    position: absolute;
                    left:0;
                    right:0;
                    bottom: 0;
                    top:0;
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                    transition: all 0.5 cubic-bezier(0.645, 0.045, 0.355, 1);
                    opacity: 0;
                }
                &:hover:after{
                    opacity: 0.85;
                }
    
                &:hover .content{
                    bottom:0;
                    background:transparent;
                }
            }
        
    }
}


.stage-cube-cont{
   
    
    position:relative ;
    
    
}

.cubespinner{
    animation-name:spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
   
    div{
        position: absolute;
        width: 200px;
        height:200px;
        border: 1px solid #ccc;
        background: rgba(255,255,255,0.4);
        font-size: 100px;
        display: flex;
        justify-content:center ;
        align-items: center;
        box-shadow: 0 0 20px 0px lightyellow;
    }
    .face1{
        transform: translateZ(100px)
    }
    .face2{
        transform: rotateY(90deg) translateZ(100px)
    }
    .face3{
        transform:rotateY(90deg) rotateX(90deg) translateZ(100px)
    }
    .face4{
        transform:rotateY(180deg) rotateZ(90deg) translateZ(100px)
    }
    .face5{
        transform:rotateY(-90deg) rotateZ(90deg) translateZ(100px)
    }
    .face6{
        transform:rotateX(-90deg) translateZ(100px)
    }
}

@keyframes spincube {
    from,
    to{
        transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);

    }
    16%{
        transform: rotateY(-90deg);
    }
    33%{
        transform: rotateY(-90deg) rotateZ(90deg);
    }
    50%{
        transform: rotateY(-180deg) rotateZ(90deg);
    }
    66%{
        transform: rotateY(-270deg) rotateZ(90deg);
    }
    83%{
        transform: rotateX(-90deg) rotateZ(90deg);
    }
}

@media only screen and (max-width:600px) {
.abt-pg{
    display: flex;
    flex-direction: column;
    gap:50px;
    position:relative;
}


.stage-cube-cont{
    position: absolute;
    margin-top: 70%;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding-left: 35%;
}

}
@media only screen and (min-width:600px) {
    .cubespinner{
        
        margin-left: calc(50% - 100px);
    }
    .stage-cube-cont{
        width: 50%;
        height:100%;
        top:0;
        padding-top: 18%;
        margin-left: 0;
        position:absolute ;
        right:0;
        overflow: hidden;
    }
}
.nav-bar {
    background: #181818;
    
    // height: 100%;
    position: absolute;
    
    display: flex;
    flex-direction: column;

    top: 0;
    z-index: 3;
    

    .logo {
        display: block;
        text-decoration: none;
        position: absolute;

        img {
            display: block;
            margin: 8px auto;
            width: 24px;
            height: auto;

            
        }
        .myName {
            text-decoration: none;
            color:white;
            display: block;
            margin:8px auto;
            font-size: 13px;
            text-align: center;
            text-transform: uppercase;
            width: 50px;
            font-family: Fantasy, Helvetica;
            font-weight: 200;
        }
    }

    nav {
        display: flex;
        padding-left: 10px;;
        flex-direction: column;
        text-align: center;
        position: absolute;
        

        a{
            font-size: 22px;
            color: #4d4d4e;
            width:100%;
            display: block;
            line-height: 51px;
            height: 51px;
            position: relative;
            text-decoration: none;
            text-align: center;
            

        

        i{
            transition: all 0.3s ease-out;
        }

        &:hover {
            color: #ffd700;

            svg {
                opacity: 0;
            }

            &:after {
                opacity: 1;
            }
        }



        &:after {
            content: '';
            font-size: 9px;
            letter-spacing: 2px;
            position: absolute;
            bottom: 0;
            display: block;
            width: 100%;
            text-align: center;
            opacity: 0;
            transition: all 0.3s ease-out;
        }

        &:first-child{
            &:after{
                content:'HOME';
            }
        }
    }
        a.about-link{
            &:after{
                content:'ABOUT';
               
            }
        }

        a.portfolio-link{
            &:after{
                content:'JOBS';
            }
        }

        a.contact-link{
            &:after{
                content:'CONTACT';
            }
        }
        a.active{
            svg{
                color:#ffd700;
            }
        }

    }
    ul{
        position: absolute;
        display: flex;
        
        padding: 0;
        list-style: none;
        text-align: center;
        
        // margin:0;

        li{
            a{
                padding: 7px 0;
                display: block;
                
                line-height: 16px;
                
                &:hover svg{
                    color: #ffd700;
                }
            }
        }
    }
}

@media only screen and (max-width:600px) {
    .nav-bar{
        width: 100%;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;  
        margin:0;
        top:0;
        height: 8rem;
        transition: all .3s ease-in-out;
        .logo{
            justify-content: center;
            align-items: center;
            padding-top:15px;
            display: block;
            height: 100%;
            align-items: left;
            width:25%;
            position: relative;

          
            
        }
        nav{
            flex-direction: row;
            position: relative;
            // width:100%;
            height: 100%;
            justify-content: space-evenly;
            width:50%;
            align-items: center;
            // background: white;
        }
        .social-top.open {
            
            visibility: visible;
            animation-name: slideBelow;
            animation-duration: 2s;
            transition: all 1.5s cubic-bezier(0.445, 0.05, 0.55, 0.95) ;
        }
        .social-top.false{
            animation-name: slideAbove;
            animation-duration: 2s;
            transition: all 1.5s cubic-bezier(0.445, 0.05, 0.55, 0.95) ;
            opacity: 0;
        }
        
        .social-top{
            
                position: absolute;
                top:80px;
                align-items: center;
                justify-content: center;
                width:50px;
                visibility: hidden;
                display: flex;
                background-color: #181818;
                right:10px;
                border-radius: 0 0 20px 20px;
        ul{

            // right:-13%;
            // transition: all 1s ease-in-out;
            right:0%;
            display: flex;
            flex-direction: column;
           
            //background:black;
            align-items: center;
            position: relative;
            justify-content: space-evenly;
            a{
                font-size: 20px;

            }
        }
        &::before,&::after{
            // transition: all 1s ease-in-out;
        }
        }
        

        .social{
            color:white;
            display: block;
            position: relative;
            line-height: 80px;
            
            align-items: center;
            text-align: right;
            padding-right: 20px;
            margin:auto;
            font-size: 25px;
            right: 0;
            width:25%;

            .social_button{
                transition: all 1s ease-in-out;
                background: none ;
                color:inherit;
                border:none;
                
                padding:0;
                font:inherit;
                cursor: pointer;
                outline: inherit;
              
            }
            .social_button.open{
                svg{
                    color:red;
                    transition:all 0.37 ease-in-out;
                }
            }
            
        
        }
        

    }

    
}

@media only screen and (min-width:600px) {
    .nav-bar{
        width: 60px;
        height: 100%;

        nav{
        height: 210px;
        top: 50%;
        margin-top: -120px;
        width: 100%;
        
        .logo{
            padding: 8px 0;
        }
        }
        ul{
            width:100%;
            bottom:20px;
            flex-direction: column;
            margin-top: auto;
            li{
                a{
                    font-size:15px;
                }
            }
        }
        .social{
            display: none;
            cursor: pointer;
        }
        
        }
    }

@keyframes slideAbove {
    from{
        margin-top:0%;
        width: 50px;
    }

    to{
        margin-top: -80px;
        width:50px
    }
    
}
@keyframes slideBelow {
    from{
        margin-top: -80px;
        width:50px
    }
    to{
        margin-top:0%;
        width: 50px;
    }
    
}
